import Loader from '../Loader'
import HeroBg from './HeroBg'
import HeroContent from './HeroContent'
import Lines from '../Lines'

const HeroSection = ({
  loaderTitle,
  loaderText,
  loaderButton,
  loaderHeroBg,
  loaderLines,
  loaderBg,
  loaderSpin
}) => (
  <div className="hero w-full h-screen t-0 relative flex justify-center items-center overflow-hidden">
    <div className="lines absolute w-full z-20">
      <Lines ref={loaderLines} />
    </div>
    {/* <Loader loaderBg={loaderBg} loaderSpin={loaderSpin} /> */}
    <HeroBg loaderHeroBg={loaderHeroBg} />
    <HeroContent
      loaderTitle={loaderTitle}
      loaderText={loaderText}
      loaderButton={loaderButton}
    />
  </div>
)

export default HeroSection