import { useRef, useEffect } from 'react'
import Text from './Text'
import FixedImage from './FixedImage'
import Lines from '../Lines'

const ServicesSection = () => {
  const section = useRef()
  const lines = useRef()
  useEffect (() => {
    const observerOptions = {
      root: null,
      rootMargin: '-5%',
      threshold: 0.3
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          lines.current.startAnimation(true)
        }
      })
    }, observerOptions)
    observer.observe(section.current)
  })
  return (
    <div className="flex w-full relative" ref={section}>
      <Text />
      <FixedImage />
      <div className="absolute w-full top-col-7" style={{ zIndex: 14 }}>
        <Lines ref={lines} />
      </div>
    </div>
  )
}

export default ServicesSection