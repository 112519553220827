import Contacts from '../Contacts'
import Logo from '../Logo'

const Footer = () => (
  <div className="footer relative bg-gray py-s">
    <div className="container grid grid-cols-1 sm:grid-cols-3 sm:items-center gap-xs sm:gap-0">
      <div className="relative h-full flex justify-start">
        <Logo />
      </div>
      <div className="sm:justify-center sm:text-center text-xs text-blue">
      Via Volturno, 80 – 20861 – Brugherio (MB) <br/>
      Via Procaccini, 32 – 20154 – Milano
      </div>
      <div className="mt-s sm:mt-0 flex flex-col sm:items-end">
        <div>
          <Contacts dark={true} color="gold" size="xs" labelColor="blue" />
        </div>
      </div>
    </div>
  </div>
)

export default Footer