import React, { useEffect } from 'react'
import StudioSection from '../StudioSection'
import ServicesSection from '../ServicesSection'
import ContactsSection from '../ContactsSection'

const PageSection = ({ section, activeSection, setActiveSection, refs }) => {
  const components = {
    studio: StudioSection,
    services: ServicesSection,
    contacts: ContactsSection
  }

  const options = {
    rootMargin: '-5%',
    threshold: 0.32
  }

  useEffect(() => {
    const handleIntersection = function(entries) {
      entries.forEach((entry) => {
        if (entry.target.dataset.section !== activeSection && entry.isIntersecting) {
          console.log(entry.target.dataset.section, entry.intersectionRatio)
          setActiveSection(entry.target.dataset.section);
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(refs[section].current);
    return () => observer.disconnect()
  }, [activeSection, setActiveSection, refs])
return (
  <div ref={refs[section]} data-section={section}>
    { React.createElement(components[section], { key: section }) }
  </div>
)
}

export default PageSection