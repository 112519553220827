import { useRef } from 'react'
import scrollAnimation from '../../../utils/scrollAnimation'

import TitleIntro from '../../Typography/TitleIntro'
import Contacts from '../../Contacts'
import AvvImage from './AvvImage'

const Top = () => {
  const title = useRef()
  const contacts = useRef()
  const address1 = useRef()
  const address2 = useRef()
  scrollAnimation(title)
  scrollAnimation(contacts)
  scrollAnimation(address1)
  scrollAnimation(address2)
  return (
    <div className="flex flex-col md:flex-row">
      <div className="bg-blue px-col-1 w-col-16 md:w-col-12 pb-l">
        <TitleIntro title="Contatti" dark={false} />
        <div className="mt-m sm:mt-l grid grid-cols-1 sm:grid-cols-2 gap-s sm:gap-col-4 relative">
          <div ref={title}>
            <h4 className="text-white font-serif text-m">Avv. Sabrina Sala</h4>
            <p className="text-gold font-sans text-s">Avvocato civilista</p>
          </div>
          <div ref={contacts}>
            <Contacts color="gold" size="xs" />
          </div>
          <div className="relative block h-full" ref={address1}>
            <h2 className="text-gold font-serif text-s">
              Studio Legale Brugherio
            </h2>
            <p className="mt-xs text-white text-xs font-sans relative block">Via Volturno, 80 - Cond. Fontana<br />20861 - Brugherio (MB)</p>
          </div>
          <div className="relative block h-full" ref={address2}>
            <h2 className="text-gold font-serif text-s">
              Studio Legale Milano
            </h2>
            <p className="mt-xs text-white text-xs font-sans relative block">Via Procaccini, 32<br />20154 - Milano</p>
          </div>
        </div>
      </div>
      <AvvImage />
    </div>
  )
}

export default Top