import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import GenericButton from '../../GenericButton'

import style from './text.module.scss'

const HeroContent = ({
  loaderTitle,
  loaderText,
  loaderButton
}) => {
  useEffect (() => {
    gsap.set([loaderTitle.current, loaderText.current], { y: 80, opacity: 0, skewY: 2, transformOrigin: 'left' })
    gsap.set(loaderButton.current, { y: 40, opacity: 0 })
  }, [])
  return (
    <div className="hero__content z-20 container">
      <h1 className="text-white text-l md:text-xl font-serif w-full sm:w-col-14 md:w-col-11" ref={loaderTitle}>
        Studio Legale Sabrina Sala. <br />
        Professionalità, competenza e affidabilità.
      </h1>
      <h2 className={`mt-s sm:mt-m text-white font-sans text-s md:text-m w-full sm:w-col-8 ${style.text}`} ref={loaderText}>
      <strong>Assistenza legale</strong> riservata ed adeguata nel campo del diritto civile. <strong>Avvocati e professionisti</strong> nelle diverse materie del diritto per rispondere ad ogni esigenza sul territorio di <strong>Brugherio</strong>, <strong>Monza e Brianza</strong>, <strong>Milano</strong>.
      </h2>
      <div className="mt-s" ref={loaderButton}>
        <a href="mailto:info@avvocatosabrinasala.it " target="_blank">
          <GenericButton label="Scrivi una mail" />
        </a>
      </div>
    </div>
  )
} 

export default HeroContent