import jpg1980x2 from './bgImages/studio-bg-1980x2.jpg'
import jpg1980x1 from './bgImages/studio-bg-1980x1.jpg'
import jpg1600x1 from './bgImages/studio-bg-1600x1.jpg'
import jpg1600x2 from './bgImages/studio-bg-1600x2.jpg'
import jpg1024x1 from './bgImages/studio-bg-1024x1.jpg'
import jpg1024x2 from './bgImages/studio-bg-1024x2.jpg'
import jpg768x2 from './bgImages/studio-bg-768x2.jpg'
import jpg768x1 from './bgImages/studio-bg-768x1.jpg'
import jpg500x1 from './bgImages/studio-bg-500x1.jpg'
import jpg500x2 from './bgImages/studio-bg-500x2.jpg'
import webp1980x2 from './bgImages/studio-bg-1980x2.webp'
import webp1980x1 from './bgImages/studio-bg-1980x1.webp'
import webp1600x1 from './bgImages/studio-bg-1600x1.webp'
import webp1600x2 from './bgImages/studio-bg-1600x2.webp'
import webp1024x1 from './bgImages/studio-bg-1024x1.webp'
import webp1024x2 from './bgImages/studio-bg-1024x2.webp'
import webp768x2 from './bgImages/studio-bg-768x2.webp'
import webp768x1 from './bgImages/studio-bg-768x1.webp'
import webp500x1 from './bgImages/studio-bg-500x1.webp'
import webp500x2 from './bgImages/studio-bg-500x2.webp'


const Bg = () => (
  <div className="bg absolute h-full w-full t-0 l-0 z-10">
    <picture>
      <source srcSet={`${webp1980x2} 2x, ${webp1980x1} 1x,`} media="(min-width: 1600px)" type="image/webp" />
      <source srcSet={`${jpg1980x2} 2x, ${jpg1980x1} 1x,`} media="(min-width: 1600px)" type="image/jpg" />
      <source srcSet={`${webp1600x2} 2x, ${webp1600x1} 1x,`} media="(min-width: 1200px)" type="image/webp" />
      <source srcSet={`${jpg1600x2} 2x, ${jpg1600x1} 1x,`} media="(min-width: 1200px)" type="image/jpg" />
      <source srcSet={`${webp1024x2} 2x, ${webp1024x1} 1x,`} media="(min-width: 768px)" type="image/webp" />
      <source srcSet={`${jpg1024x2} 2x, ${jpg1024x1} 1x,`} media="(min-width: 768px)" type="image/jpg" />
      <source srcSet={`${webp768x2} 2x, ${webp768x1} 1x,`} media="(min-width: 500px)" type="image/webp" />
      <source srcSet={`${jpg768x2} 2x, ${jpg768x1} 1x,`} media="(min-width: 500px)" type="image/jpg" />
      <source srcSet={`${webp500x2} 2x, ${webp500x1} 1x,`} media="(min-width: 0px)" type="image/webp" />
      <source srcSet={`${jpg500x2} 2x, ${jpg500x1} 1x,`} media="(min-width: 0px)" type="image/jpg" />
      <img src={jpg1980x1} alt="Avvocato Sabrina Sala" className="w-full h-full" style={{objectFit: 'cover'}} />
    </picture>
  </div>
)

export default Bg