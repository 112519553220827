import { useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import styles from './lines.module.scss'
import gsap from 'gsap'

const Lines = forwardRef((props, ref) => {
  let paths
  const loaderLines = useRef()
  useEffect(() => {
    paths = loaderLines.current.querySelectorAll('path')

    paths.forEach((path, i) => {
      const length = path.getTotalLength()
      gsap.set(paths, { strokeDasharray: length, strokeDashoffset: length })
    })
  }, [])
  useImperativeHandle(ref, () => ({
    startAnimation: (reverse = false) => {
      console.log('lines')
      paths = loaderLines.current.querySelectorAll('path')
      paths.forEach((path, i) => {
        const length = path.getTotalLength()
        const onComplete = () => {
          if (!reverse) return
          gsap.to(path, { strokeDashoffset: -length, ease: 'expo.inOut', duration: 6.2, delay: i * 0.36 })
        }
        gsap.to(path, { strokeDashoffset: 0, ease: 'expo.inOut', duration: 6.2, delay: i * 0.36, onComplete })
      })
    }
  }))
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 841.9 344.3"
      className={styles.lines}
      ref={loaderLines}
    >
      <path d="M-12.7 152.8s45.3 56 140 20 181.3-156 303.3-106.7 236.7 110.7 416 52.7"/>
      <path d="M-13.3 115.5s90 92.7 267.3 88.7 247.3-142.7 367.3-124S796.7 208.8 876 208.8"/>
      <path d="M-14 130.1c2 0 82.7 92 228 96s192.7-80 192.7-80S507.3-3.9 620 98.8s193.3 73.3 235.3 58.7" />
      <path d="M-120.7 31.5S50-82.5 284 123.5s442.7 32.7 442.7 32.7l164-130"/>
      <path d="M-115.3 242.1s134 176.7 280.7 65.3 160.7-178 329.3-132 354.7-40 354.7-40"/>
    </svg>
  )
})

export default Lines