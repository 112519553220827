import MailIcon from '../Icons/MailIcon'
import styles from './button.module.scss'

const GenericButton = ({ label }) => (
  <div className={`py-xs px-xs inline-block ${styles.button} relative`}>
    <div className={`bg w-full h-full absolute left-0 top-0 z-10 ${styles.bg}`} />
    <div className="flex items-center relative z-20">
      <MailIcon color="gold" size="xs" />
      <div className={`label font-sans text-s ml-xxs leading-none ${styles.label}`}>
        { label }
      </div>
    </div>
  </div>
)

export default GenericButton