import React, { createRef, useState, useEffect } from 'react'

import sections from './datasets/menu'
import { gsap } from 'gsap'

import Topbar from './components/Topbar'
import Hero from './components/Hero'
import PageSection from './components/PageSection'
import Footer from './components/Footer'

const App = () => {
  const [activeSection, setActiveSection] = useState()

  const refs = sections.reduce((refsObj, section) => {
    refsObj[section.id] = createRef();
    return refsObj;
  }, {});

  const loaderTitle = createRef(null)
  const loaderText = createRef(null)
  const loaderHeroBg = createRef(null)
  const loaderButton = createRef(null)
  const loaderLines = createRef(null)
  const topbar = createRef(null)

  const handleClick = (item) => {
    refs[item].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
  useEffect (() => {  
  

    const loaderTl = gsap.timeline({ paused: true })
    loaderTl
      .to(loaderHeroBg.current, {
        scale: 1,
        rotation: 0,
        ease: 'expo.inOut',
        duration: 1.6,
      }, 0.36)
      .to(loaderTitle.current, {
        opacity: 1,
        y: 0,
        skewY: 0,
        duration: 1.2,
        ease: 'expo.inOut'
      }, 0.36)
      .to(loaderText.current, {
        opacity: 1,
        y: 0,
        skewY: 0,
        duration: 1.2,
        ease: 'expo.inOut'
      }, 0.42)
      .to(loaderButton.current, {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: 'expo.inOut'
      }, 0.48)
      .to(topbar.current, {
        yPercent: 0,
        ease: 'expo.inOut',
        duration: 0.8
      }, 0.6)

      loaderTl.play()
  }, [])
  return (
    <div className="App">
      <Topbar
        topbar={topbar}
        activeSection={activeSection}
        handleClick={handleClick}
      />
      <Hero
        loaderTitle={loaderTitle}
        loaderText={loaderText}
        loaderButton={loaderButton}
        loaderHeroBg={loaderHeroBg}
        loaderLines={loaderLines}
      />
      { sections.map((section) => (
        <PageSection
          key={section.id}
          refs={refs}
          section={section.id}
          activeSection={activeSection}
          setActiveSection={setActiveSection} 
        />
      ))
      }
      <Footer />
    </div>
    )
}

export default App;
