import { useState, useEffect } from 'react'

import DesktopTopbar from './DesktopTopbar'
import MobileTopbar from './MobileTopbar'

const Topbar = ({ activeSection, handleClick, topbar }) => {
  const [isMobile, setDevice] = useState(window.innerWidth < 768);
  const [small, setSmall] = useState(false)
  useEffect (() => {
    const handleWindowResize = () => {
      setDevice(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleWindowResize)
    window.addEventListener('scroll', () => {
      if (window.scrollY > 25) {
        setSmall(true)
      } else {
        setSmall(false)
      }
    })
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  return isMobile ? (
    <MobileTopbar activeSection={activeSection} handleClick={handleClick} topbar={topbar} small={small} />
  )
  : (
    <DesktopTopbar activeSection={activeSection} handleClick={handleClick} topbar={topbar} small={small} />
  )
}

export default Topbar