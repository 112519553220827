export default [
  {
    title: `Tutela del soggetto fragile`,
    text: `Esperienza specifica in ambito di tutela dei soggetti fragili (anziani e persone con disabilità fisica o psichica) e offre pertanto assistenza in materia di <strong>amministrazione di sostegno</strong> e di <strong>interdizione</strong>, oltre che in <strong>materia previdenziale</strong> ed <strong>invalidità</strong>.`
  },
  {
    title: `Diritto di famiglia`,
    text: `Consolidata esperienza in materia di <strong>separazione</strong>, <strong>divorzio</strong> e di <strong>tutela giuridica</strong> della cosiddetta famiglia di fatto. Vista la delicatezza delle tematiche lo studio è particolarmente attento al rapporto umano che si instaura con il cliente.`
  },
  {
    title: `Recupero crediti`,
    text: `Assistenza in tema di recupero crediti sia in <strong>fase stragiudiziale</strong> che in <strong>sede giudiziale</strong>, ivi comprese le procedure esecutive (pignoramenti mobiliari, immobiliari e presso terzi). `
  },
  {
    title: `Diritto condominiale`,
    text: `Consulenza e assistenza in materia condominiale, con particolare riferimento all’<strong>impugnazione delle delibere assembleari</strong>, <strong>violazione delle disposizioni</strong> di cui al Reg. Condominiale, <strong>azioni nei confronti degli Amministratori</strong>, <strong>problemi tra condomini</strong> e <strong>recupero delle morosità maturate dai condomini</strong>.`
  },
  {
    title: `Locazioni`,
    text: `Assistenza sia ai proprietari che ai conduttori e si occupa di ogni aspetto relativo all’instaurazione, allo svolgimento e alla cessazione del <strong>contratto di locazione</strong> ivi compresa la <strong>procedura di sfratto</strong> e di <strong>recupero dei canoni di locazione</strong> non corrisposti.`
  },
  {
    title: `Diritto del lavoro`,
    text: `Assistenza in ogni aspetto connesso al rapporto di lavoro: instaurazione, svolgimento e conclusione. Gran parte dell’attività riguarda le questioni giuridiche connesse al <strong>licenziamento</strong> e al <strong>recupero crediti</strong> sia dal lato datoriale che dal lato dipendente, per quest’ultimo anche nell’ambito delle <strong>procedure concorsuali</strong> (fallimenti, concordati preventivi, amministrazioni straordinarie, ecc.) nonché della fase successiva di accesso al Fondo di Garanzia dell’INPS.`,
    multiRow: true
  },
  {
    title: `Risarcimento danni da fatto illecito`,
    text: `Esperienza nel campo della <strong>responsabilità civile</strong> extracontrattuale anche per danni da circolazione stradale.`
  }
]