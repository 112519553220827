const menu = [
  {
    label: 'Studio',
    id: 'studio'
  },
  {
    label: `Servizi e attività`,
    id: 'services'
  },
  {
    label: 'Contatti',
    id: 'contacts'
  }
]

export default menu