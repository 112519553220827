import jpg886x2 from './images/services-side-886x2.jpg'
import jpg886x1 from './images/services-side-886x1.jpg'
import jpg700x1 from './images/services-side-700x1.jpg'
import jpg700x2 from './images/services-side-700x2.jpg'
import jpg630x2 from './images/services-side-630x2.jpg'
import jpg630x1 from './images/services-side-630x1.jpg'
import jpg448x1 from './images/services-side-448x1.jpg'
import jpg448x2 from './images/services-side-448x2.jpg'
import webp886x2 from './images/services-side-886x2.webp'
import webp886x1 from './images/services-side-886x1.webp'
import webp700x1 from './images/services-side-700x1.webp'
import webp700x2 from './images/services-side-700x2.webp'
import webp630x2 from './images/services-side-630x2.webp'
import webp630x1 from './images/services-side-630x1.webp'
import webp448x1 from './images/services-side-448x1.webp'
import webp448x2 from './images/services-side-448x2.webp'

const FixedImage = () => (
  <div className="hidden sm:w-col-4 md:w-col-7 md:block h-screen flex justify-center overflow-hidden sticky top-0 z-10">
    <picture>
      <source srcSet={`${webp886x2} 2x, ${webp886x1} 1x,`} media="(min-width: 1600px)" type="image/webp" />
      <source srcSet={`${jpg886x2} 2x, ${jpg886x1} 1x,`} media="(min-width: 1600px)" type="image/jpg" />
      <source srcSet={`${webp700x2} 2x, ${webp700x1} 1x,`} media="(min-width: 1440px)" type="image/webp" />
      <source srcSet={`${jpg700x2} 2x, ${jpg700x1} 1x,`} media="(min-width: 1440px)" type="image/jpg" />
      <source srcSet={`${webp630x2} 2x, ${webp630x1} 1x,`} media="(min-width: 1024px)" type="image/webp" />
      <source srcSet={`${jpg630x2} 2x, ${jpg630x1} 1x,`} media="(min-width: 1024px)" type="image/jpg" />
      <source srcSet={`${webp448x2} 2x, ${webp448x1} 1x,`} media="(min-width: 0)" type="image/webp" />
      <source srcSet={`${jpg448x2} 2x, ${jpg448x1} 1x,`} media="(min-width: 0)" type="image/jpg" />
      <img src={jpg886x1} alt="Avvocato Sabrina Sala" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </picture>
  </div>
)

export default FixedImage