import colors from '../../../datasets/colors'

const MailIcon = ({ color, size }) => {
  const style = {
    textIndent: 0,
    textTransform: 'none',
    blockProgression: 'tb',
    fill: colors[color]
  }
  const sizes = {
    xxs: 'w-xxs',
    xs: 'w-xs',
    s: 'w-s',
    m: 'w-m',
    l: 'w-l',
    xl: 'w-xl',
  }
  return (
    <div className={`${sizes[size]} h-auto mr-xxs`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 85"><path style={style} d="M17 20c-4.996 0-8.9999995 4.2165-8.9999995 9.2188v41.53122c0 5.0023 4.0039995 9.25 8.9999995 9.25h66c4.995999 0 9-4.2477 9-9.25V29.2188C92 24.2165 87.995999 20 83 20H17zm.4375 6h65.1563L51.7812 55.25002c-1.1213 1.0647-2.3756 1.0658-3.5 0L17.4375 26zM14 31l20.4375 19.37502L14 70.75002V31zm72 0v39.75002l-20.375-20.4062L86 31zM61.3125 54.46882l19.468699 19.5312H19.25l19.5312-19.5 5.375 5.0938c3.2582 3.0882 8.492501 3.0931 11.75 0l5.4063-5.125z" /></svg>
    </div>
  )
}

export default MailIcon