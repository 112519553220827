import { useState, useEffect } from "react"
import { gsap } from "gsap"

export default (ref) => {
  const [isInViewport, setIsInViewport] = useState(false) 
  const [animationDone, setAnimationDone] = useState(false)
  const [timeline] = useState(gsap.timeline({ paused: true }))
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '-10%',
      threshold: 0.32
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInViewport(true)
        } else {
          setIsInViewport(false)
        }
      })
    }, observerOptions)
    
    observer.observe(ref.current)

    gsap.set(ref.current, {
      y: 32,
      skewY: 4,
      transformOrigin: 'left',
      opacity: 0
    })
    timeline.to(ref.current, {
      y: 0,
      skewY: 0,
      opacity: 1,
      duration: 0.8,
      ease: 'expo.out',
      onComplete: () => {
        setAnimationDone(true)
      }
    });
  }, []);
  useEffect(() => {
    if (animationDone) return
    if (isInViewport) timeline.play()
  }, [isInViewport])
}