import contacts from '../../datasets/contacts.js'
import MailIcon from '../Icons/MailIcon'
import PhoneIcon from '../Icons/PhoneIcon'
import styles from './contacts.module.scss'

const Contacts = ({ color, size, labelColor = 'white' }) => {
  const colors = {
    white: 'text-white',
    gold: 'text-gold',
    blue: 'text-blue'
  }
  return (
    contacts.map((item) => (
      <a
        href={ item.type === 'phone' ? 'tel:' + item.value : 'mailto:' + item.value}
        target="_blank"
        className={`${styles.contact} flex`} key={item.value}
      >
        <div className="icon">
          { item.type === 'phone' ? (
            <PhoneIcon color={color} size={size}></PhoneIcon>
          ) : (
            <MailIcon color={color} size={size}></MailIcon>
          )
          }
        </div>
        <div
          className={`text-xs label ${colors[labelColor]} font-sans font-bold font-medium`}
        >
            { item.label }
        </div>
      </a>
    ))
  )
}

export default Contacts