const contacts = [
  {
    type: 'phone',
    label: '+39 039 91.44.403',
    value: '00390399144403'
  },
  {
    type: 'mail',
    label: 'info@avvocatosabrinasala.it ',
    value: 'info@avvocatosabrinasala.it '
  }
]

export default contacts