import { useRef } from 'react'
import scrollAnimation from '../../../utils/scrollAnimation'

import TitleIntro from '../../Typography/TitleIntro'
import servicesText from '../../../datasets/servicesText'

const Text = () => {
  const text = useRef()
  const services = Array.from({length: servicesText.length}, () => useRef(null));
  scrollAnimation(text)
  services.map((service) => {
    scrollAnimation(service)
  })
  return (
    <div className="w-col-16 md:w-col-12 px-col-1" style={{ zIndex: 15 }}>
      <TitleIntro title="Servizi e Attività" />
      <div className="text-m font-serif text-blue" ref={text}>
        <p>Grazie alla rete di contatti e collaborazioni professionali sviluppate negli anni, lo <strong>studio</strong> è in grado di fornire una vasta proposta di servizi ed attività per il cliente. <br/>
        In base alle necessità possiamo fornire <strong>consulenze specifiche</strong> avvalendoci di professionisti quali <strong>notai</strong>, <strong>consulenti del lavoro</strong>, <strong>psicologi</strong> e <strong>professionisti</strong> dedicati alle singole materie per casi specifici.</p>
      </div>
      <div className="my-m grid grid-cols-1 sm:grid-cols-2 gap-s sm:gap-m">
        {
          servicesText.map((text, i) => (
            <div className={text.multiRow ? 'row-span-2' : ''} key={i} ref={services[i]}>
              <h2 className="text-gold font-serif text-m">{ text.title }</h2>
              <p className="mt-xs color-black font-sans text-s" dangerouslySetInnerHTML={{ __html: text.text }}></p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Text