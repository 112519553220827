import Top from './Top'
import Bottom from './Bottom'

const ContactsSection = () => (
  <div>
    <Top />
    <Bottom />
  </div>
)

export default ContactsSection