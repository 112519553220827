import gsap from 'gsap/gsap-core'
import menu from '../../../datasets/menu.js'
import Logo from '../../Logo'
import Contacts from '../../Contacts'
import styles from '../topbar.module.scss'

const DesktopTopbar = ({ activeSection, handleClick, topbar, small }) => (
  <div className={`topbar fixed flex w-full z-50`} ref={topbar}>
    <div className={`topbar__left pl-col-1 pr-col-1/2 bg-gray w-full sm:w-col-11 md:w-col-12 py-xs flex justify-between items-center ${styles.transition} ${small ? styles.small : ''}`}>
      <Logo />
      <div className="logo origin-left"></div>
      <div
        className="menu flex left-0 flex-col sm:flex-row absolute sm:relative w-full sm:w-auto bg-gray sm:bg-none origin-right"
      >
        { menu.map((item) => (
          <div className="px-s" key={item.id}>
            <div
              className={`relative text-xs inline-block menu__item font-sans font-medium text-blue uppercase py-xs sm:py-0 ${styles.menu__item} ${activeSection === item.id ? styles.isActive : ''}`}
              key={item.id}
              onClick={() => handleClick(item.id)}>
                { item.label }
            </div>
          </div>
        ))}
      </div>
    </div>
    {
      <div className={`topbar__right px-col-1/2 bg-blue sm:w-col-4 md:w-col-4 py-xs flex items-start flex-col ${styles.transition} ${small ? styles.small : ''}`}>
        <div className="origin-left">
          <Contacts color="gold" size="xs" />
        </div>
      </div>
    }
  </div>
)

export default DesktopTopbar