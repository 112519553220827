import Bg from './Bg'
import GenericButton from '../../GenericButton'

const Bottom = () => (
  <div className="relative">
    <div className="absolute z-10 top-0 left-0 h-full w-full overflow-hidden">
      <Bg />
    </div>
    <div className="container relative py-l flex flex-col sm:flex-row justify-between items-start sm:items-center relative z-20">
      <div>
        <h1 className="font-serif text-l text-white">Richiedi una consulenza</h1>
        <p className="mt-xs font-sans text-500 text-white">Lo studio legale offre la possibilità di <strong className=" text-500 text-gold">consulenza in studio e online su appuntamento.</strong></p>
      </div>
      <div className="mt-s sm:mt-0 relative">
        <a href="mailto:info@avvocatosabrinasala.it " target="_blank">
          <GenericButton label="Scrivi una mail" />
        </a>
      </div>
    </div>
  </div>
)

export default Bottom