import { useRef } from 'react'
import TitleIntro from '../Typography/TitleIntro'
import Bg from './Bg'
import scrollAnimation from '../../utils/scrollAnimation'

const StudioSection = () => {
  const main = useRef()
  const text = useRef()
  scrollAnimation(main);
  scrollAnimation(text);
  return (
    <div>
      <div className="pb-m sm:pb-xl overflow-hidden relative">
        <Bg />
        <div className="container z-20 relative">
          <TitleIntro title="Studio Legale" />
          <div className="mt-s">
            <h2 className="text-blue text-m sm:text-l font-serif w-full sm:w-col-12" ref={main}>
            L’<strong>Avvocato Sabrina Sala</strong> offre <strong>consulenza legale</strong> e assistenza sia giudiziale che stragiudiziale in ambito civile su tutto il territorio nazionale con particolare attenzione al territorio di Brugherio, <strong>Monza e Brianza</strong> e <strong>Milano</strong>. Grazie alla rete di contatti e collaborazioni professionali attive, sviluppate negli anni, lo Studio è in grado di fornire un ampio ventaglio di servizi professionali, assistenza legale e difesa.
            </h2>
            <div className="w-col-12 mx-auto sm:grid sm:grid-cols-2 gap-col-4 text-s text-black font-sans mt-m" ref={text} >
              <p>Lo Studio Legale nasce nel 2014, da subito opera nel contenzioso civile, con una forte propensione alla tutela del soggetto fragile e del diritto di famiglia.</p>
              <p>Nel 2015 il percorso professionale si interseca con l’impegno sociale, attraverso la collaborazione con Associazione InCerchio per le persone fragili con sede in Milano, di cui l’Avvocato Sabrina Sala è stata Presidente.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudioSection