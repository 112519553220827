import { useState, useRef, useEffect } from 'react'

import { gsap } from 'gsap'

import menu from '../../../datasets/menu.js'
import Logo from '../../Logo'
import Contacts from '../../Contacts'
import styles from '../topbar.module.scss'

const MobileTopbar = ({ activeSection, handleClick, topbar, small }) => {
  const menuRef = useRef()
  const [ menuHeight, setMenuHeight ] = useState()
  const [ menuIsOpen, setMenuState ] = useState(false) 
  useEffect (() => {
    setMenuHeight(menuRef.current.scrollHeight)
    gsap.set(menuRef.current, { height: 0 })
  })
  const toggleMenu = () => {
    if (menuIsOpen) {
      gsap.to(menuRef.current, { height: 0, duration: 0.36, ease: 'expo.out' })
      setMenuState(!menuIsOpen)
      return
    }
    gsap.to(menuRef.current, { height: menuHeight, duration: 0.36, ease: 'expo.out' })
    setMenuState(!menuIsOpen)
  }
  const onClick = (id) => {
    handleClick(id)
    setMenuState(false)
  }
  return (
    <div className="mobile-topbar fixed w-full z-50" ref={topbar}>
      <div className={`flex relative justify-between items-center bg-gray p-xs w-full ${styles.transitionmobile} ${small ? styles.smallmobile : ''}`}>
        <Logo />
        <div className={`${styles.hamburger} ${menuIsOpen && styles.open}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`menu relative flex flex-col bg-gray overflow-hidden`} ref={menuRef}>
        { menu.map((item) => (
          <div className="px-s" key={item.id}>
            <div
              className={`relative text-xs inline-block menu__item font-sans font-medium text-blue uppercase py-xs sm:py-0 ${styles.menu__item} ${activeSection === item.id ? styles.isActive : ''}`}
              key={item.id}
              onClick={() => onClick(item.id)}>
                { item.label }
            </div>
          </div>
        ))}
        <div className="w-full p-xs bg-blue">
          <Contacts color="gold" size="xs" />
        </div>
      </div>
    </div>
  )
}

export default MobileTopbar