import { useRef } from 'react'
import scrollAnimation from '../../../utils/scrollAnimation'

const TitleIntro = ({ title, dark = true }) => {
  const ref = useRef()
  scrollAnimation(ref)
  return (
    <h2 className="mt-m sm:mt-l mb-s sm:mb-m flex text-s font-sans" ref={ref}>
    <div className={ dark ? 'text-blue' : 'text-white'}>– </div>
    <div className="text-gold uppercase ml-xxs">{ title }</div>
  </h2>
  )
}

export default TitleIntro